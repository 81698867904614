import React from "react";
import "./WhiteLabel.css";
import AutoPlatestimonials from "./TestimonialsSlider";
const WhiteLabel = () => {
  return (
    <div>
      <main id="white_label_crypto_main">
        <section className="white_label_crypto_banner">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-7 col-md-12 col-sm-12 col-12">
                <div>
                  <h1 className=" title-10">
                    White Label <b> Exchange</b> Development Company
                  </h1>
                  <p className="contents">
                    To quickly create your cryptocurrency exchange platform, use our dependable and secure white label crypto exchange software solutions.

                  </p>
                  <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
                    <a
                      className="btn wl_btn dark_btn"
                      data-toggle="modal"
                      data-target="#popup_form_modal"
                    >
                      Schedule a Demo{" "}
                    </a>
                    <a
                      className="btn wl_btn line_btn"
                      href=" "
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="white_label_crypto_sec">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-12">
                <h2 className="grident-color-sub-title title-10">Overview</h2>
                <div className="line_sec">
                  <span className="dot" />
                  <span className="line" />
                </div>
                <p className="contents">
                  Our extensive advice for creating your own White-label cryptocurrency exchange software.

                </p>
                <div className="white_label_crypto_btn_sec">
                  <a
                    className="btn white_label_crypto_btn"
                    data-toggle="modal"
                    data-target="#popup_form_modal"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="overview_list_sec">
                  <a href="#white_label_crypto_comp">
                    <div className="overview_list">
                      <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />
                      <p className="sub_title-10">
                        White Label Cryptocurrency Exchange Development Firm
                      </p>
                    </div>
                  </a>
                  <a href="#white_label_crypto_features">
                    <div className="overview_list">
                      <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />

                      <p className="sub_title-10">
                        Features of White Label Crypto Exchange Software
                      </p>
                    </div>
                  </a>
                  <a href="#our_solutions_based_security">
                    <div className="overview_list">
                      <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />

                      <p className="sub_title-10">
                        Our security measure-based solutions
                      </p>
                    </div>
                  </a>
                  <a href="#white_label_crypto_types">
                    <div className="overview_list">
                      <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />

                      <p className="sub_title-10">
                        Software Types for White Label Crypto Exchanges
                      </p>
                    </div>
                  </a>
                  <a href="#white_label_crypto_benefits">
                    <div className="overview_list">
                      <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />

                      <p className="sub_title-10">
                        Advantages of White Label Crypto Exchange Software
                      </p>
                    </div>
                  </a>
                  <a href="#white_label_crypto_exchange_modules">
                    <div className="overview_list">
                      <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />

                      <p className="sub_title-10">
                        Modules for White Label Crypto Exchanges
                      </p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <a href="#cryptocurrency_exchange_software_function">
                  <div className="overview_list">
                    <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />

                    <p className="sub_title-10">
                      Software for white-label bitcoin exchanges
                    </p>
                  </div>
                </a>
                <a href="#top_crypto_exchange">
                  <div className="overview_list">
                    <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />

                    <p className="sub_title-10">
                      Clone Script for Best Crypto Exchange
                    </p>
                  </div>
                </a>
                <a href="#white_label_crypto_process">
                  <div className="overview_list">
                    <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />

                    <p className="sub_title-10">
                      The Software Development Process for Crypto Exchanges
                    </p>
                  </div>
                </a>
                <a href="#white_label_crypto_whychoose">
                  <div className="overview_list">
                    <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />

                    <p className="sub_title-10">
                      Why Opt for MetaBlock When Developing Software for Crypto Exchanges?
                    </p>
                  </div>
                </a>
                <a href="#white_label_crypto_hire">
                  <div className="overview_list">
                    <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />

                    <p className="sub_title-10">
                      Employ Developers To Build Crypto Exchanges

                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section
          className="white_label_crypto_comp"
          id="white_label_crypto_comp"
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-12">
                <h2 className="grident-color-sub-title title-10">
                  Development Company for White Label Crypto Exchanges

                </h2>
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <p className="contents">
                      One of the top White Label Crypto Exchange Software Development Companies, MetaBlock, provides ready-made software with significant features, a fervent user interface, robust APIs, and incredibly mind-blowing capabilities for cryptocurrency traders. Software that is ready for the deployment trading of cryptocurrencies, security tokens, and digital assets is something you, the website owners, may expect.

                    </p>
                    <p className="contents">
                      Anybody can easily create their own digital asset exchange platform in a matter of hours by making the customizations they like. These platforms offer high levels of security, rapid deployment, and cost-effectiveness when built from the ground up.

                    </p>
                    <div className="whl_crypto_about_btn_sec">
                      <a
                        href=" "
                        className="btn whl_crypto_about_btn desktop"
                        target="_blank"
                      >
                        <i className="fab fa-whatsapp" />
                        &nbsp;WhatsApp
                      </a>
                      <a
                        href=" "
                        className="btn whl_crypto_about_btn mobile"
                        target="_blank"
                      >
                        <i className="fab fa-whatsapp" />
                        &nbsp; WhatsApp{" "}
                      </a>
                      <a
                        href=" "
                        className="btn whl_crypto_about_btn1 desktop"
                        target="_blank"
                      >
                        <i className="fab fa-skype" />
                        &nbsp;Skype
                      </a>
                      <a
                        href=" "
                        className="btn whl_crypto_about_btn1 mobile"
                        target="_blank"
                      >
                        <i className="fab fa-skype" />
                        &nbsp;Skype
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="white_label_crypto_features"
          id="white_label_crypto_features"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="wl_features_head">
                  <h2 className="grident-color-sub-title title-10">
                    Features of White Label Crypto Exchange Software
                  </h2>
                  <span className="line_center" />
                  <p className="contents">
                    Some essential features have been incorporated into our White Label Crypto Exchange Software. That will serve as a guide for any businessman to have a stable business journey.

                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className>
                  <ul className="nav nav-tabs wl_features_nav_tabs">
                    <li>
                      <a
                        href="#wl_features_tab1"
                        className="active"
                        data-toggle="tab"
                      >
                        Admin Features
                      </a>
                    </li>
                    <li>
                      <a href="#wl_features_tab2" data-toggle="tab">
                        User Features
                      </a>
                    </li>
                    <li>
                      <a href="#wl_features_tab3" data-toggle="tab">
                        Security Features
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content wl_features_tab_content">
                    <div className="tab-pane active" id="wl_features_tab1">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <a
                            data-toggle="collapse"
                            data-parent=".tab-pane"
                            href="#wl_features_collapse1"
                          >
                            <h4 className="panel-title-10">Admin Features</h4>
                            <i className="fa fa-chevron-down" />
                          </a>
                        </div>
                        <div
                          id="wl_features_collapse1"
                          className="panel-collapse collapse"
                        >
                          <div className="panel-body">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Full management of FAQ selection
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Several handles on social networking accounts
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    A fast look at past transactions

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Remarks and the feedback mechanism

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Sensitive administrative dashboard

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Keep an eye on conflict resolution

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Keep the API option for liquidity.

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Affiliate schemes

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Setting Management for Multiple Linguistics

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Launchpad management
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Management of Trade Pairs

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Tools for Adding Dynamic Tokens

                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="wl_features_tab2">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <a
                            data-toggle="collapse"
                            data-parent=".tab-pane"
                            href="#wl_features_collapse2"
                          >
                            <h4 className="panel-title-10">User Features</h4>
                            <i className="fa fa-chevron-down" />
                          </a>
                        </div>
                        <div
                          id="wl_features_collapse2"
                          className="panel-collapse collapse"
                        >
                          <div className="panel-body">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Positive P2P trade highlights
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Features of quick spot trading

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    A decent option for trading derivatives

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Optimal Margin Management

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Notable Over-the-Counter Trading

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">Copy Trading</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Facility for Cold Wallets

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Safe Dispute Resolution System

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Remarkable Integrated Core Cash

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Using Credit or Debit Cards, Purchase Cryptos

                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="wl_features_tab3">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <a
                            data-toggle="collapse"
                            data-parent=".tab-pane"
                            href="#wl_features_collapse3"
                          >
                            <h4 className="panel-title-10">Security Features</h4>
                            <i className="fa fa-chevron-down" />
                          </a>
                        </div>
                        <div
                          id="wl_features_collapse3"
                          className="panel-collapse collapse"
                        >
                          <div className="panel-body">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Automated AML & KYC

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">Two-Factor Security
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">CSRF Protection</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">SSRF Protection</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Software to Prevent Phishing

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Encryption of databases

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">Jail Login</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">DDoS Mitigation</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Multi-Sig Core Wallet
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Temporary Transaction

                                  </p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="features_list">
                                  <p className="sub_content">
                                    Verification using Biometrics

                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="solution_security"
          id="our_solutions_based_security"
        >
          <div className="container">
            <h2 className="grident-color-sub-title title-10">Based on security measures, our solutions
            </h2>
            <p className="contents">
              We employ the most advanced security measures, and we update the relevant sections on a regular basis to take into account market developments.

            </p>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="types_box">
                  <h3 className="sub_title-10 text-center grident-color-sub-title">Private key approach
                  </h3>
                  <p className="sub_content">
                    Each cryptocurrency wallet can only be opened with a single private key. All of the private keys are stored with the highest degree of security.

                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="types_box">
                  <h3 className="sub_title-10 text-center grident-color-sub-title">2FA as well as anti-phishing protocols</h3>
                  <p className="sub_content">
                    Additional safety precautions for the confidential data stored on your exchange include of two-factor authentication and anti-phishing techniques.

                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="types_box">
                  <div className="sub_title-10 text-center grident-color-sub-title">Support and recuperation</div>
                  <p className="sub_content">
                    Procedures for daily data backups and synchronous backups are included in the software package. Periodically, the consistency of the backed-up data is verified.

                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="types_box">
                  <h3 className="sub_title-10 text-center grident-color-sub-title">Wallet division
                  </h3>
                  <p className="sub_content">
                    Deposits and withdrawals are handled using several wallets.

                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="types_box">
                  <h3 className="sub_title-10 text-center grident-color-sub-title">Multi-level encryption</h3>
                  <p className="sub_content">
                    We make use of symmetric, asymmetric, and hashing encryption methods. Every imaginable component combines these in one way or another.

                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="types_box">
                  <h3 className="sub_title-10 text-center grident-color-sub-title">Warm and chilly pocketbooks
                  </h3>
                  <p className="sub_content">
                    Before being moved to cold wallets, funds are kept in hot wallets for a brief period of time.

                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="white_label_crypto_platform"
          id="white_label_crypto_platform"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-12">
                <h2 className=" title-10">
                  With our unique white label bitcoin exchange solution, you can completely transform your business.

                </h2>
                <div className="white_label_crypto_btn_sec">
                  <a
                    className="btn white_label_crypto_btn"
                    data-toggle="modal"
                    data-target="#popup_form_modal"
                  >
                    Talk To Our Expert
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-12" />
            </div>
          </div>
        </section>
        <section
          className="white_label_crypto_types"
          id="white_label_crypto_types"
        >
          <div className="container">
            <h2 className="grident-color-sub-title title-10">
              Software Types for White Label Crypto Exchanges

            </h2>
            <p className="contents">
              The blockchain technology improves and enables crowdfunding in a number of ways. Platforms powered by blockchain technology, for instance, do not need an intermediary.

            </p>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="types_box">
                  <img width={'60x'}
                    src="./images/currency-exchange.png"
                    alt="Centralized Exchange Development"
                    title-10="Centralized Exchange Development"
                  />
                  <div className="sub_title-10 text-center grident-color-sub-title">
                    Developed Centralized Exchange

                  </div>
                  <p className="sub_content">
                    Together with a strong trading engine for speedy order matching and transaction processing, MetaBlock offers an incredible centralized crypto exchange script. To give us an advantage over the competition, our professionals are capable of combining cutting-edge features like security, functionality, UI/UX, and scalability into a single platform.

                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="types_box">
                  <img
                    width={'60px'}
                    src="./images/exchange.png"
                    alt="Decentralized Exchange Development"
                    title-10="Decentralized Exchange Development"

                  />
                  <h3 className="sub_title-10 text-center grident-color-sub-title">
                    Development of Decentralized Exchanges
                  </h3>
                  <p className="sub_content">
                    Our decentralized trading platform is proud of its Ethereum smart contract, which provides consumers with high-tech features above cryptocurrency exchange platforms. With a well-thought-out design for bitcoin exchange platforms, our decentralized platform helps customers trade wisely, simply, and safely.

                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="types_box">
                  <img
                    width={'60px'}
                    src="./images/hybrid.png"
                    alt="Hybrid Exchange Development"
                    title-10="Hybrid Exchange Development"
                  />
                  <h3 className="sub_title-10 text-center grident-color-sub-title">Development of Hybrid Exchanges
                  </h3>
                  <p className="sub_content">
                    Our hybrid exchange is run by professionals with incredible experience working with trade terminal developers and entrepreneurs who have successfully run stock and futures exchanges. It provides quick transactions and significant liquidity.

                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="types_box">
                  <img
                    width={'60px'}
                    src="./images/peer-to-peer.png"
                    alt="P2P Exchange Development"
                    title-10="P2P Exchange Development"
                  />
                  <h3 className="sub_title-10 text-center grident-color-sub-title">Development of P2P Exchanges</h3>
                  <p className="sub_content">
                    By choosing to create a peer-to-peer cryptocurrency exchange platform that offers a hassle-free method of transferring cryptocurrency assets, traders can have a dedicated framework without the need for middlemen.Dispute management, auto KYC and AML, atomic swap, escrow system, and multilayer security.

                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="types_box">
                  <img
                    width={'60px'}
                    src="./images/derivative.png"
                    alt="Derivatives Exchange Development"
                    title-10="Derivatives Exchange Development"
                  />
                  <div className="sub_title-10 text-center grident-color-sub-title">
                    Development of Derivatives Exchange

                  </div>
                  <p className="sub_content">
                    The establishment of derivatives exchange platforms enables trading on the DEX (decentralized exchange) and CEX (centralized exchange) platforms. In order to attract more investors, exchange owners can take advantage of the possibilities of a cryptocurrency derivatives exchange.

                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="types_box">
                  <img
                    width={'60px'}
                    src="./images/bitcoin.png"
                    alt="Margin Trading Exchange Development"
                    title-10="Margin Trading Exchange Development"
                  />
                  <h3 className="sub_title-10 text-center grident-color-sub-title">
                    Exchange Development for Margin Trading
                  </h3>
                  <p className="sub_content">
                    One of the most important developments in the cryptocurrency sector is the creation of the Margin trading platform. When purchasing or selling bitcoin, the trader has the option to borrow money to increase possible profits. Use margin trading as a win-win strategy for owners and users by leveraging it.

                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="types_box">
                  <img
                    width={'60px'}
                    src="./images/escrow-account.png"
                    alt="Escrow Exchange Development"
                    title-10="Escrow Exchange Development"
                  />
                  <h3 className="sub_title-10 text-center grident-color-sub-title">Development of Escrow Exchange</h3>
                  <p className="sub_content">
                    Create an exceptionally The Crypto Exchange Platform is overseen by Escrow Protocol and provides a fast trading engine, multi-currency pairing, and limitless support for Crypto tokens to meet your business needs.

                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="types_box">
                  <img
                    width={'60px'}
                    src="./images/trading.png"
                    alt="OTC Trading Platform Development"
                    title-10="OTC Trading Platform Development"
                  />
                  <h3 className="sub_title-10 text-center grident-color-sub-title">
                    Development of OTC Trading Platforms

                  </h3>
                  <p className="sub_content">
                    Create a top-tier over-the-counter exchange platform in order to transact a significant volume of cryptocurrency. A decentralised market is essentially a market system made up of different types of technological equipment. With the help of this arrangement, investors can establish a marketplace decentralized from a single point.

                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="types_box">
                  <img
                    width={'60px'}
                    src="./images/virtual.png"
                    alt="Futures Exchange Development"
                    title-10="Futures Exchange Development"
                  />
                  <div className="sub_title-10 text-center grident-color-sub-title">Development of Futures Exchanges</div>
                  <p className="sub_content">
                    Futures Exchange Development is a platform that serves as a marketplace for a variety of cryptocurrency products that are prepared for index futures, as well as for the buying and selling of options on futures contracts.

                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="types_box">
                  <img
                    width={'60px'}
                    src="./images/gdp.png"
                    alt="ETF Trading Development"
                    title-10="ETF Trading Development"
                  />
                  <h3 className="sub_title-10 text-center grident-color-sub-title">ETF Trading Development </h3>
                  <p className="sub_content">
                    The creation of an investment fund-based platform that holds assets like stocks, commodities, bonds, or foreign currencies is known as an Exchange-Traded Fund (ETF) development. The ETF platform offers reduced transaction costs, enhanced market accessibility, more transparency, and robust security.

                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="types_box">
                  <img
                    width={'60px'}
                    src="./images/exchange-rate.png"
                    alt="Copy Trading Exchange Development"
                    title-10="Copy Trading Exchange Development"
                  />
                  <h3 className="sub_title-10 text-center grident-color-sub-title">
                    Trade Exchange Development in Copy Form

                  </h3>
                  <p className="sub_content">
                    The goal of Copy Trading Exchange is to create an exchange platform that will enable all participants in the financial and cryptocurrency markets to automatically copy positions in the markets. These positions are opened and managed by another expert trader.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="types_box">
                  <img
                    width={'60px'}
                    src="./images/cryptocurrency.png"
                    alt="Crypto Contract Trading Development"
                    title-10="Crypto Contract Trading Development"
                  />
                  <h3 className="sub_title-10 text-center grident-color-sub-title">
                    Development of Crypto Contract Trading

                  </h3>
                  <p className="sub_content">
                    The creation of crypto contract trading offers a fully functional automated trading environment that functions as a screen-based, floor-less, bug-free cryptocurrency trading system on a national scale. The practice of trading assets that enables traders to obtain a greater amount of capital through leverage.

                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* -------------------- */}
        <section
          className="white_label_crypto_benefits"
          id="white_label_crypto_benefits"
        >
          <div className="container">
            <h2 className="grident-color-sub-title title-10">
              The Advantages for Businesses of Selecting Our White Label Exchange Software Script

            </h2>
            <div className="row justify-content-center">
              <div className="col-lg-7 col-12">
                <div className="benefits_list_sec">
                  <div className="benefits_list">
                    {/* <img
                      src="asset/images/inner-pages/white_lable_crypto/blue-tick.gif"
                      className="img-fluid"
                      alt="tick animation"
                      title-10="tick animation"
                    /> */}
                    <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />
                    <div className="sub_title-10">
                      With ease, the administrator can launch the application anywhere, at any time.

                    </div>
                  </div>
                  <div className="benefits_list">
                    {/* <img
                      src="asset/images/inner-pages/white_lable_crypto/blue-tick.gif"
                      className="img-fluid"
                      alt="tick animation"
                      title-10="tick animation"
                    /> */}
                    <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />

                    <div className="sub_title-10">
                      An Affordable Cryptocurrency Exchange

                    </div>
                  </div>
                  <div className="benefits_list">
                    {/* <img
                      src="asset/images/inner-pages/white_lable_crypto/blue-tick.gif"
                      className="img-fluid"
                      alt="tick animation"
                      title-10="tick animation"
                    /> */}
                    <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />

                    <div className="sub_title-10">
                      UI/UX customization for visually appealing websites

                    </div>
                  </div>
                  <div className="benefits_list">
                    {/* <img
                      src="asset/images/inner-pages/white_lable_crypto/blue-tick.gif"
                      className="img-fluid"
                      alt="tick animation"
                      title-10="tick animation"
                    /> */}
                    <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />

                    <div className="sub_title-10">
                      Transactions can be completed quickly and efficiently with just an eye closure.

                    </div>
                  </div>
                  <div className="benefits_list">
                    {/* <img
                      src="asset/images/inner-pages/white_lable_crypto/blue-tick.gif"
                      className="img-fluid"
                      alt="tick animation"
                      title-10="tick animation"
                    /> */}
                    <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />

                    <div className="sub_title-10">
                      Separate panel to examine every action made within the program

                    </div>
                  </div>
                  <div className="benefits_list">
                    {/* <img
                      src="asset/images/inner-pages/white_lable_crypto/blue-tick.gif"
                      className="img-fluid"
                      alt="tick animation"
                      title-10="tick animation"
                    /> */}
                    <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />

                    <div className="sub_title-10">
                      The user will have access to dependable cryptocurrency trading

                    </div>
                  </div>
                  <div className="benefits_list">
                    {/* <img
                      src="asset/images/inner-pages/white_lable_crypto/blue-tick.gif"
                      className="img-fluid"
                      alt="tick animation"
                      title-10="tick animation"
                    /> */}
                    <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />

                    <div className="sub_title-10">
                      Enhanced Precision and Effectiveness

                    </div>
                  </div>
                  <div className="benefits_list">
                    {/* <img
                      src="asset/images/inner-pages/white_lable_crypto/blue-tick.gif"
                      className="img-fluid"
                      alt="tick animation"
                      title-10="tick animation"
                    /> */}
                    <i style={{ fontSize: '25px' }} className="fa fa-arrow-circle-right" aria-hidden="true" />

                    <div className="sub_title-10">
                      No requirement for a tech expert

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="white_label_crypto_platform"
          id="white_label_crypto_platform"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-12">
                <h2 className=" title-10">
                  Are you prepared to start the process of establishing your own exchange platform?

                </h2>
                <div className="white_label_crypto_btn_sec">
                  <a
                    className="btn white_label_crypto_btn"
                    data-toggle="modal"
                    data-target="#popup_form_modal"
                  >
                    Talk To Our Expert
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-12" />
            </div>
          </div>
        </section>
        <section
          className="white_label_crypto_modules"
          id="white_label_crypto_exchange_modules"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-12">
                <h2 className="grident-color-sub-title title-10">Modules for White Label Crypto Exchanges</h2>
                <p className="contents">
                  With a plethora of features, our white-label cryptocurrency exchange software includes extensive modules that customize your cryptocurrency exchange.

                </p>
                <div className="white_label_crypto_btn_sec">
                  <a
                    className="btn wl_btn dark_btn"
                    data-toggle="modal"
                    data-target="#popup_form_modal"
                  >
                    Get Quote
                  </a>
                  <a
                    className="btn wl_btn line_btn"
                    href=" "
                  >
                    Get Demo
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="modules_list">
                      <div className="num_sec">01</div>
                      <div className="sub_title-10">Rich Data Admin Panel
                      </div>
                    </div>
                    <div className="modules_list">
                      <div className="num_sec">03</div>
                      <div className="sub_title-10">Modes of Trade
                      </div>
                    </div>
                    <div className="modules_list">
                      <div className="num_sec">05</div>
                      <div className="sub_title-10">Gateway for payments
                      </div>
                    </div>
                    <div className="modules_list">
                      <div className="num_sec">07</div>
                      <div className="sub_title-10">
                        AML & KYC Verification

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="modules_list">
                      <div className="num_sec">02</div>
                      <div className="sub_title-10">History of Transactions
                      </div>
                    </div>
                    <div className="modules_list">
                      <div className="num_sec">04</div>
                      <div className="sub_title-10">Tool for Adding Tokens
                      </div>
                    </div>
                    <div className="modules_list">
                      <div className="num_sec">06</div>
                      <div className="sub_title-10">Transaction Information
                      </div>
                    </div>
                    <div className="modules_list">
                      <div className="num_sec">08</div>
                      <div className="sub_title-10">Program for Referrals
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="exchange_software_work"
          id="cryptocurrency_exchange_software_function"
        >
          <div className="container">
            <h2 className="grident-color-sub-title title-10">
              What are the workings of our White-label cryptocurrency exchange software?

            </h2>
            <p className="contents">
              An exchange for cryptocurrencies is a website where buyers and sellers may trade cryptocurrencies for fiat money or other forms of currency. Choosing the right exchange platform is crucial before participating in the bitcoin campaign. View the features of our white label bitcoin exchange software and discover why it is the ideal choice for you.

            </p>
            <div className="container_line">
              <div className="timeline-block timeline-block-right">
                <div className="marker" />
                <div className="timeline-content">
                  <p>
                    After selecting our bitcoin exchange program, register for an account.

                  </p>
                </div>
              </div>
              <div className="timeline-block timeline-block-left">
                <div className="marker" />
                <div className="timeline-content">
                  <p>When your account is created, fund it.</p>
                </div>
              </div>
              <div className="timeline-block timeline-block-right">
                <div className="marker" />
                <div className="timeline-content">
                  <p>Using fiat money, buy cryptocurrency
                  </p>
                </div>
              </div>
              <div className="timeline-block timeline-block-left">
                <div className="marker" />
                <div className="timeline-content">
                  <p>
                    Transform one cryptocurrency into another or fiat money.

                  </p>
                </div>
              </div>
              <div className="timeline-block timeline-block-right">
                <div className="marker" />
                <div className="timeline-content">
                  <p>
                    Keep your money on the exchange or move it to the integrated cryptocurrency wallet.

                  </p>
                </div>
              </div>
              <div className="timeline-block timeline-block-left">
                <div className="marker" />
                <div className="timeline-content">
                  <p>
                    Exchange cryptocurrencies for fiat money Transfer the money to this account, then take it out.

                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="top_crypto_exchange_clone_script"
          id="top_crypto_exchange"
        >
          <div className="container-fluid h-100">
            <div className="nftgp_custom_container">
              <div className="row">
                <div className="col-md-12 ">
                  <div className="nftgp_sectitle-10_wrap">
                    <h3 className="grident-color-sub-title title-10 mt-0 mb-0">
                      Clone Script for Best Crypto Exchange

                    </h3>
                  </div>
                </div>
              </div>
              <div className="row  mt-5">
                <div className="col-md-12 ">
                  <ul>
                    <li className>
                      <a
                        href=" "
                        target="_blank"
                      >
                        <div className="nftgp_box  ">
                          <div className="nftgp_img"></div>
                          <div className="nftgp_desc">Binance Clone script</div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href=" "
                        target="_blank"
                      >
                        <div className="nftgp_box">
                          <div className="nftgp_img"></div>
                          <div className="nftgp_desc">Wazirx Clone script</div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href=" "
                        target="_blank"
                      >
                        <div className="nftgp_box">
                          <div className="nftgp_img"></div>
                          <div className="nftgp_desc">
                            Remitano Clone script
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href="  "
                        target="_blank"
                      >
                        <div className="nftgp_box">
                          <div className="nftgp_img"></div>
                          <div className="nftgp_desc">Paxful Clone script</div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href=" "
                        target="_blank"
                      >
                        <div className="nftgp_box">
                          <div className="nftgp_img"></div>
                          <div className="nftgp_desc">
                            Localbitcoins Clone script
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li className>
                      <a
                        href=" "
                        target="_blank"
                      >
                        <div className="nftgp_box  ">
                          <div className="nftgp_img"></div>
                          <div className="nftgp_desc">
                            Coinbase Clone script
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href=" "
                        target="_blank"
                      >
                        <div className="nftgp_box">
                          <div className="nftgp_img"></div>
                          <div className="nftgp_desc">Kucoin Clone script</div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href=" "
                        target="_blank"
                      >
                        <div className="nftgp_box">
                          <div className="nftgp_img"></div>
                          <div className="nftgp_desc">Kraken Clone script</div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href=" "
                        target="_blank"
                      >
                        <div className="nftgp_box">
                          <div className="nftgp_img"></div>
                          <div className="nftgp_desc">Huobi Clone script</div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href=" "
                        target="_blank"
                      >
                        <div className="nftgp_box">
                          <div className="nftgp_img"></div>
                          <div className="nftgp_desc">
                            Poloniex Clone script
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="white_label_crypto_process"
          id="white_label_crypto_process"
        >
          <div className="container-fluid">
            <div className="row row_justify">
              <div className="col-lg-11">
                <div>
                  <h2 className="grident-color-sub-title title-10">
                    The Software Development Process for Crypto Exchanges

                  </h2>
                  <div className="row row_justify process_line">
                    <div className="col-lg-2 col-12">
                      <div className="process_list">
                        <span className="process_round">1</span>
                        <p className="sub_title-10 text-center grident-color-sub-title ">Analysis of Requirements
                        </p>
                        <p className="sub_content">
                          We do a thorough examination of the potential areas in order to conduct trade.

                        </p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-12">
                      <div className="process_list">
                        <span className="process_round">2</span>
                        <p className="sub_title-10 text-center grident-color-sub-title">Design Procedure
                        </p>
                        <p className="sub_content">
                          We construct the platform in accordance with the responsibilities of the user.

                        </p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-12">
                      <div className="process_list">
                        <span className="process_round">3</span>
                        <p className="sub_title-10 text-center grident-color-sub-title">Included Attribute
                        </p>
                        <p className="sub_content">
                          We design the application based on company needs, algorithms, and specifications.

                        </p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-12">
                      <div className="process_list">
                        <span className="process_round">4</span>
                        <p className="sub_title-10 text-center grident-color-sub-title">Verification of Security
                        </p>
                        <p className="sub_content">
                          To ensure a bug-free application, UAT and hazard testing have been conducted.

                        </p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-12">
                      <div className="process_list">
                        <span className="process_round">5</span>
                        <p className="sub_title-10 text-center grident-color-sub-title">Gateway for payments</p>
                        <p className="sub_content">
                          We have made usability better by integrating with several payment gateways.

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="testiSc">
          <div className="container-fluid">
            <div className="row rwJsut">
              <div className="col-lg-11">
                <div>
                  <h2 className="testiScTlt title-10_alict1 grident-color-sub-title">Testimonials</h2>
                  <p className="testiScCnt">Get feedback from our customers</p>
                  <div className="row rwJsut">
                    <div className="col-lg-9">
                      <div>
                        <div className="carousel-wrap">
                          <div className="owl-carousel" id="test_1">
                            <AutoPlatestimonials />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="white_label_crypto_whychoose"
          id="white_label_crypto_whychoose"
        >
          <div className="container">
            <h2 className="grident-color-sub-title title-10">
              Why should software developers for cryptocurrency exchanges use MetaBlock?

            </h2>
            <div className="row align-items-center">
              <div className="col-lg-7 col-12">
                <p className="contents">
                  The technoscientific supporting team is provided by MetaBlock; they are accessible around-the-clock to assist clients with a range of problems. Our team comprises developers for White Label Crypto Exchange that are proficient in creating features with distinctive and adjustable coding.

                </p>
                <p className="contents">
                  Being a reliable source for White Label Crypto Exchange Software development, MetaBlock Technologies has the Solana developer knowledge required to satisfy customers' needs. Within 48 hours, we can create and deliver the program, as well as offer you the assistance you require to get going straight away.

                </p>
              </div>
              <div className="col-lg-4 col-12">
                <div className="why_choose_sec">
                  <div className="why_choose_list">
                    <i
                      className="fa fa-arrow-circle-right"
                      aria-hidden="true"
                    />
                    <div className="sub_title-10">Timely completion of the project</div>
                  </div>
                  <div className="why_choose_list">
                    <i
                      className="fa fa-arrow-circle-right"
                      aria-hidden="true"
                    />
                    <div className="sub_title-10">Competent Creator of NFTs</div>
                  </div>
                  <div className="why_choose_list">
                    <i
                      className="fa fa-arrow-circle-right"
                      aria-hidden="true"
                    />
                    <div className="sub_title-10">50+ blockchain experts</div>
                  </div>
                  <div className="why_choose_list">
                    <i
                      className="fa fa-arrow-circle-right"
                      aria-hidden="true"
                    />
                    <div className="sub_title-10">Importance of the client's needs</div>
                  </div>
                  <div className="why_choose_list">
                    <i
                      className="fa fa-arrow-circle-right"
                      aria-hidden="true"
                    />
                    <div className="sub_title-10">After-development assistance</div>
                  </div>
                  <div className="why_choose_list">
                    <i
                      className="fa fa-arrow-circle-right"
                      aria-hidden="true"
                    />
                    <div className="sub_title-10">
                      Collaboration between clients and managers
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="white_label_crypto_hire"
          id="white_label_crypto_hire"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12" id="wl_crypto_hire_card">
                <div className="row align-items-center">
                  <div className="col-lg-8 col-12">
                    <h2 className=" title-10">
                      Employ Developers to Build Crypto Exchanges
                    </h2>
                    <p className="contents">
                      We offer comprehensive assistance at every stage of the process, from the creation of the blockchain to the cryptocurrency trading platform. Join forces with us to create a scalable, dependable, and safe cryptocurrency exchange platform that will enable seamless trades. We will be pleased to offer our skilled and knowledgeable team of developers assistance to you.

                    </p>
                    <div className="white_label_crypto_btn_sec">
                      <a
                        className="btn white_label_crypto_btn"
                        data-toggle="modal"
                        data-target="#popup_form_modal"
                      >
                        Get A Live Demo
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default WhiteLabel;
