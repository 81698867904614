import p1_img from "./product_1.png";
import p2_img from "./product_2.png";
import p3_img from "./product_3.png";
import p4_img from "./product_4.png";
import p5_img from "./product_5.png";
import p6_img from "./product_6.png";


let news_data = [
    {
      id: 1,
      name: "navratan",
      category: "women",
      image: p1_img,
      new_price: 50.0,
      old_price: 80.5,
      Paragraph: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae cumque ipsam sint fuga dignissimos similique nihil quidem, ex eaque debitis mollitia inventore quia, architecto necessitatibus voluptate non animi qui alias repellendus reprehenderit quis dolore adipisci pariatur rerum? Ex in veritatis voluptatibus, ab fugiat odio sed consectetur ratione nulla illum velit? Suscipit veniam asperiores, ad illo inventore reiciendis provident explicabo repellendus dolore nobis pariatur, reprehenderit dicta, aliquam facilis iure consequatur libero eos praesentium dolores atque voluptatem placeat neque perferendis quod. Placeat libero exercitationem nisi pariatur repellat possimus recusandae velit delectus? Maxime nesciunt consectetur hic quidem amet nemo consequatur dolorem asperiores ex. Odit commodi quaerat animi aperiam labore repudiandae, explicabo cumque excepturi? Consectetur asperiores doloribus repudiandae deserunt nisi inventore, exercitationem error repellendus laudantium reiciendis pariatur, illo suscipit? Animi recusandae saepe, quos, voluptates commodi ipsam quasi vitae sed laboriosam, officiis quis necessitatibus dolorum similique minima eligendi ipsum. Ratione ducimus illo magnam amet possimus."
  
    },
    {
      id: 2,
      name: "nora",
      category: "women",
      image: p2_img,
      new_price: 85.0,
      old_price: 120.5,
         Paragraph: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae cumque ipsam sint fuga dignissimos similique nihil quidem, ex eaque debitis mollitia inventore quia, architecto necessitatibus voluptate non animi qui alias repellendus reprehenderit quis dolore adipisci pariatur rerum? Ex in veritatis voluptatibus, ab fugiat odio sed consectetur ratione nulla illum velit? Suscipit veniam asperiores, ad illo inventore reiciendis provident explicabo repellendus dolore nobis pariatur, reprehenderit dicta, aliquam facilis iure consequatur libero eos praesentium dolores atque voluptatem placeat neque perferendis quod. Placeat libero exercitationem nisi pariatur repellat possimus recusandae velit delectus? Maxime nesciunt consectetur hic quidem amet nemo consequatur dolorem asperiores ex. Odit commodi quaerat animi aperiam labore repudiandae, explicabo cumque excepturi? Consectetur asperiores doloribus repudiandae deserunt nisi inventore, exercitationem error repellendus laudantium reiciendis pariatur, illo suscipit? Animi recusandae saepe, quos, voluptates commodi ipsam quasi vitae sed laboriosam, officiis quis necessitatibus dolorum similique minima eligendi ipsum. Ratione ducimus illo magnam amet possimus."
    },
    {
      id: 3,
      name: "bhai",
      category: "women",
      image: p3_img,
      new_price: 60.0,
      old_price: 100.5,      
       Paragraph: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae cumque ipsam sint fuga dignissimos similique nihil quidem, ex eaque debitis mollitia inventore quia, architecto necessitatibus voluptate non animi qui alias repellendus reprehenderit quis dolore adipisci pariatur rerum? Ex in veritatis voluptatibus, ab fugiat odio sed consectetur ratione nulla illum velit? Suscipit veniam asperiores, ad illo inventore reiciendis provident explicabo repellendus dolore nobis pariatur, reprehenderit dicta, aliquam facilis iure consequatur libero eos praesentium dolores atque voluptatem placeat neque perferendis quod. Placeat libero exercitationem nisi pariatur repellat possimus recusandae velit delectus? Maxime nesciunt consectetur hic quidem amet nemo consequatur dolorem asperiores ex. Odit commodi quaerat animi aperiam labore repudiandae, explicabo cumque excepturi? Consectetur asperiores doloribus repudiandae deserunt nisi inventore, exercitationem error repellendus laudantium reiciendis pariatur, illo suscipit? Animi recusandae saepe, quos, voluptates commodi ipsam quasi vitae sed laboriosam, officiis quis necessitatibus dolorum similique minima eligendi ipsum. Ratione ducimus illo magnam amet possimus."
  
    },
    {
      id: 4,
      name: "ohooho",
      category: "women",
      image: p4_img,
      new_price: 100.0,
      old_price: 150.0,
      Paragraph: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae cumque ipsam sint fuga dignissimos similique nihil quidem, ex eaque debitis mollitia inventore quia, architecto necessitatibus voluptate non animi qui alias repellendus reprehenderit quis dolore adipisci pariatur rerum? Ex in veritatis voluptatibus, ab fugiat odio sed consectetur ratione nulla illum velit? Suscipit veniam asperiores, ad illo inventore reiciendis provident explicabo repellendus dolore nobis pariatur, reprehenderit dicta, aliquam facilis iure consequatur libero eos praesentium dolores atque voluptatem placeat neque perferendis quod. Placeat libero exercitationem nisi pariatur repellat possimus recusandae velit delectus? Maxime nesciunt consectetur hic quidem amet nemo consequatur dolorem asperiores ex. Odit commodi quaerat animi aperiam labore repudiandae, explicabo cumque excepturi? Consectetur asperiores doloribus repudiandae deserunt nisi inventore, exercitationem error repellendus laudantium reiciendis pariatur, illo suscipit? Animi recusandae saepe, quos, voluptates commodi ipsam quasi vitae sed laboriosam, officiis quis necessitatibus dolorum similique minima eligendi ipsum. Ratione ducimus illo magnam amet possimus."
    },
    {
      id: 5,
      name: "douye",
      category: "women",
      image: p5_img,
      new_price: 85.0,
      old_price: 120.5,
      Paragraph: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae cumque ipsam sint fuga dignissimos similique nihil quidem, ex eaque debitis mollitia inventore quia, architecto necessitatibus voluptate non animi qui alias repellendus reprehenderit quis dolore adipisci pariatur rerum? Ex in veritatis voluptatibus, ab fugiat odio sed consectetur ratione nulla illum velit? Suscipit veniam asperiores, ad illo inventore reiciendis provident explicabo repellendus dolore nobis pariatur, reprehenderit dicta, aliquam facilis iure consequatur libero eos praesentium dolores atque voluptatem placeat neque perferendis quod. Placeat libero exercitationem nisi pariatur repellat possimus recusandae velit delectus? Maxime nesciunt consectetur hic quidem amet nemo consequatur dolorem asperiores ex. Odit commodi quaerat animi aperiam labore repudiandae, explicabo cumque excepturi? Consectetur asperiores doloribus repudiandae deserunt nisi inventore, exercitationem error repellendus laudantium reiciendis pariatur, illo suscipit? Animi recusandae saepe, quos, voluptates commodi ipsam quasi vitae sed laboriosam, officiis quis necessitatibus dolorum similique minima eligendi ipsum. Ratione ducimus illo magnam amet possimus."
    },
    {
      id: 6,
      name: "kdjshjkdhjhsdkj",
      category: "women",
      image: p6_img,
      new_price: 85.0,
      old_price: 120.5,
      Paragraph: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae cumque ipsam sint fuga dignissimos similique nihil quidem, ex eaque debitis mollitia inventore quia, architecto necessitatibus voluptate non animi qui alias repellendus reprehenderit quis dolore adipisci pariatur rerum? Ex in veritatis voluptatibus, ab fugiat odio sed consectetur ratione nulla illum velit? Suscipit veniam asperiores, ad illo inventore reiciendis provident explicabo repellendus dolore nobis pariatur, reprehenderit dicta, aliquam facilis iure consequatur libero eos praesentium dolores atque voluptatem placeat neque perferendis quod. Placeat libero exercitationem nisi pariatur repellat possimus recusandae velit delectus? Maxime nesciunt consectetur hic quidem amet nemo consequatur dolorem asperiores ex. Odit commodi quaerat animi aperiam labore repudiandae, explicabo cumque excepturi? Consectetur asperiores doloribus repudiandae deserunt nisi inventore, exercitationem error repellendus laudantium reiciendis pariatur, illo suscipit? Animi recusandae saepe, quos, voluptates commodi ipsam quasi vitae sed laboriosam, officiis quis necessitatibus dolorum similique minima eligendi ipsum. Ratione ducimus illo magnam amet possimus."
    },
]
    export default news_data;