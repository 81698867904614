import React, { Component } from "react";
import Slider from "react-slick";
import './OnDemandAppSlider.css'
import './CardSlider.scss'
import { useNavigate } from "react-router-dom";
import Visitbutton from "./Visitbutton";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInstagram } from '@fortawesome/free-brands-svg-icons';
// import { faHeart, faShare } from '@fortawesome/free-solid-svg-icons';

const SoftwareCarousel = [
  {
    name: "ERP Software",
    path: "/ERPSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/01.png",
    className: 'item-10'
  },
  {
    name: "SAAS Software",
    path: "/SAASSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/02-1.png",
    className: 'item-10'

  },
  {
    name: "Warehouse Managment Software",
    path: "/WarehouseManagmentSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/03-1.png",
    className: 'item-10'


  },
  {
    name: "HRMS Software",
    path: "/HRMSSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/04.png",
    className: 'item-10'
  },
  {
    name: "Inventory Managment Software",
    path: "/InventoryManagmentSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/05-1.png",
    className: 'item-10'
  },
  {
    name: "Accounting & Billing Software",
    path: "/AccountingBillingSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/06.png"
    ,
    className: 'item-10'
  },
  {
    name: "POS System Software",
    path: "/POSSystemSoftware",
    image: "https://themeht.com/template/soften/ltr/images/about/03.png"
    ,
    className: 'item-10'
  },
  {
    name: "Employee Tracking Software",
    path: "/EmployeeTrackingSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/01.png",
    className: 'item-10'
  },
  {
    name: "Attendance System Software",
    path: "/AttendanceSystemSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/02-1.png",
    className: 'item-10'
  },
  {
    name: "Library Managment System",
    path: "/LibraryManagmentSystem",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/03-1.png",
    className: 'item-10'
  },
  {
    name: "LMS With Live Class Software",
    path: "/LMSWithLiveClassSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/04.png",
    className: 'item-10'
  },
  {
    name: "University Managment  Software",
    path: "/UniversityManagmentSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/05-1.png",
    className: 'item-10'
  },
  {
    name: "Quiz System Software",
    path: "/QuizSystemSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/01.png",
    className: 'item-10'
  },
  {
    name: "Astrology Software",
    path: "/AstrologySoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/02-1.png",
    className: 'item-10'
  },
  {
    name: "Travel Booking Software",
    path: "/TravelBookingSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/03-1.png",
    className: 'item-10'
  },
  {
    name: "Matrimonial Software",
    path: "/MatrimonialSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/04.png",
    className: 'item-10'
  },
  {
    name: "Restaurant Managment  Software",
    path: "/RestaurantManagmentSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/05-1.png",
    className: 'item-10'
  },
  {
    name: "Hotel Managment Software",
    path: "/HotelManagmentSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/06.png",
    className: 'item-10'
  },
  {
    name: "Event management Software",
    path: "/EventmanagementSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/06.png",
    className: 'item-10'
  },
  {
    name: "Hospital Managment System Software",
    path: "/HospitalManagementSoftware",
    image: "https://themeht.com/template/soften/ltr/images/about/03.png",
    className: 'item-10'
  },
  {
    name: "Real state Software",
    path: "/RealstateSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/02-1.png",
    className: 'item-10'
  },
  {
    name: "Clinic Managment Software",
    path: "/ClinicManagmentSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/03-1.png",
    className: 'item-10'
  },
  {
    name: "MLM Software",
    path: "/MLMSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/04.png",
    className: 'item-10'
  },
  {
    name: "Trading Software",
    path: "/TradingSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/05-1.png",
    className: 'item-10'
  },
  {
    name: "Job Portal Software",
    path: "/JobPortalSoftware",
    image: "https://soften.themeht.com/wp-content/uploads/2023/12/06.png",
    className: 'item-10'
  },
];


const OnDemandAppSlider = () => {
  const navigate = useNavigate();


  const settings = {
    infinite: true,
    // dots: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 2000,
    responsive: [
      {
        breakpoint: 588,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ]
  };

  return (
    <div className="slider-main-div-1"
      style={{ marginBottom: '40px' }}
    >
      <h2 className="ondemand-head grident-color-sub-title" style={{ color: 'black', fontSize: '40px' }}>On Demand Software
      </h2>
      <div className="slider-div">

        <Slider {...settings}>
          {SoftwareCarousel.map((item, index) => (


            <div key={index}>
              <div className={item.className}>
                <div>

                  <img src={item.image} alt="" />

                </div>
                <div className="img-upper-background">
                </div>
                <div className="visit-now-button" onClick={() => navigate(item.path)}>
                  {/* <Visitbutton /> */}
                  <button className="btn-hover"><span>{item.name}</span></button>
                  {/*  <button className="btn-hover"><span>{item.name}<i class="fa-solid fa-right-long"></i></span></button> */}
                </div>

              </div>

            </div>
          ))}

        </Slider>
      </div>
    </div>
  );
};

export default OnDemandAppSlider;