import React from 'react'
import './BrandsWork.css'
const BrandsWork = () => {
    return (
        <>
            <section className="section-heading mid_section-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 grident-color-sub-title">
                            <h2 style={{ color: "#D166AF ", fontSize: "31px" }} className="text-center Technology-Stack ">Brands We Have Worked With</h2>
                        </div>
                    </div>
                    <div className="row tech p-5 pt-0 mt-5 mb-0" style={{ display: "flex", justifyContent: "space-between" }}>

                        <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                            <img src="./images/haawks.png" className="w-100 h-40" alt="" />
                            <h5>Haawks</h5>
                        </div>

                        <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                            <img src="./images/Dsea.png" alt="" className="w-100 h-40" />
                            <h5>Dsea</h5>

                        </div>

                        <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                            <img src="./images/Buffy-guffy.png" alt="" className="w-100 h-40" />
                            <h5>Buffy Guffy</h5>
                        </div>


                        <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                            <img src="./images/Defilogo.png" alt="" className="w-100 h-40" />
                            <h5>Defi</h5>
                        </div>

                        <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                            <img src="./images/Ecommerce.webp" alt="" className="w-100 h-40" />
                            <h5>Ecommerce</h5>
                        </div>
                    </div>


                    <div className="brands-we-have-worked row tech p-5 pt-0 mt-0 mb-0" style={{ display: "flex", justifyContent: "space-between" }}>

                        <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                            <img src="./images/taTrading.jpg" alt="" className="w-100 h-40" />
                            <h5>TA Trading</h5>
                        </div>

                        <div className=" col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                            <img src="./images/Analytical.png" alt="" className="w-100 h-40" />
                            <h5>Analytical</h5>
                        </div>

                        <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                            <img src="./images/Donation.png" alt="" className="w-100 h-40" />
                            <h5>Donation</h5>
                        </div>


                        <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                            <img src="./images/zuraverses.jpg" alt="" className="w-100 h-40" />
                            <h5>Zuraverses</h5>
                        </div>

                        <div className="col-md-2 mt-5 d-flex justify-center align-center flex-column gap-3">
                            <img src="./images/Bookishow.png" alt="" className="w-100 h-40" />
                            <h5>Bookishow</h5>
                        </div>
                    </div>



                </div>
            </section>
        </>
    )
}

export default BrandsWork;
