import React from 'react'
import "./NFTToken.css"

const NFTToken = () => {
  return (
    <div>
      <main className="nft_token_development">
        <section className="banner_sec">
          <div className="container-fluid">
            <div className="row row_just" style={{ marginTop: "10vh" }}>
              <div className="col-lg-6">
                <div>
                  <h2 className="ourservice-title"><span className="spel_txt">NFT Token</span> Development</h2>
                  <p className="contents"> We With the aid of MetaBlock, you may develop and introduce a highly functional non-fungible token that meets the needs of your particular company. On several blockchain systems, including Ethereum, EOS, Tron, Binance Smart Chain, and others, we produce a unique NFT Token.
                  </p>
                  <div className="btn_style">
                    <a className="btn banner_btn" data-toggle="modal" data-target="#popup_form_modal">Get Live
                      Demo</a>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-12 nft-development-container-right-div' />
              <div className="col-lg-6 col-12 nft-development-container-right">
                <img src="./images/NFT-Tokan-banner.jpg" alt="Clone List" title="Clone List" className="img-fluid lazyload text-center"
                  style={{ width: '500px', height: '300px', borderRadius: '5px', backgroundPosition: 'center', backgroundSize: 'cover' }} />
              </div >
            </div>
          </div>
        </section>
        <section className="non_fungible_token">
          <div className="container">
            <div className="row row_just">
              <div className="col-lg-12">
                <div className="row row_align">
                  <div className="col-lg-5">
                    <div className="pt-5">
                      <img style={{ width: "500px", borderRadius: "10px" }} src="./images/nft-nonfungible.png" alt="Non-Fungible Token" title="Non-Fungible Token" />

                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div>
                      <h2 className="ourservice-titles-new grident-color-sub-title">Non-Fungible Tokens: What Are They?
                      </h2>
                      <p className="contents">One of the cryptocurrency market's fastest-growing segments is non-fungible tokens, or NFTs. It functions as a digital asset, security token, privacy token, and utility token. That falls under the categories of blockchain technology and multiple cryptography. It serves as ownership evidence and is an indivisible, one-of-a-kind token connected to digital and occasionally physical material.
                      </p>
                      <p className="contents">Because of its uniqueness and non-interchangeability, it cannot be replaced by other tokens that are similar. In contrast, the NFT Token is made up of unique data that is kept in smart contracts. NFTs, also known as ERC-721 tokens or TRC-721 tokens, are based on the Ethereum protocol, also known as ERC-721, and TRON's TRC-721. There are numerous applications for them, such as digital collectibles, music, video game objects, artwork, and the Metaverse.
                      </p>
                      <div className="social_icon">
                        <a className="btn bitcoin_whats" href="https://wa.me/9828284421">Whatsapp</a>
                        <a className="btn bitcoin_tele" href="https://web.telegram.org/k/#@metablocktechnologies">Telegram</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="service-grid pb-5 pt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 text-center mb-4">
                <div className="service-title">
                  <h4 className="ourservice-titles-new grident-color-sub-title">Various User-focused NFT Development Solutions Are Offered by Us!
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="service-wrap d-flex align-center justify-center flex-column">
                  <div className="service-icon">
                    <img src="./images/blockchain.png" />
                  </div>
                  <h4 className="grident-color-sub-title text-center ">Development of Non-Fungible Tokens
                  </h4>
                  <p className="sub-content">To increase the value of your digital collectibles, we provide your token with attributes like scarcity, indivisibility, uniqueness, and transparency. For different businesses, there are different token standards such as TRC-721, ERC-1155, ERC-998, and ERC-721.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="service-wrap d-flex align-center justify-center flex-column">
                  <div className="service-icon">
                    <img src="./images/ethereum.png" />
                  </div>
                  <h4 className="grident-color-sub-title text-center">Development of NFT Minting Platform
                  </h4>
                  <p className="sub-content">Without requiring any coding or technological experience, create an NFT Minting platform with us from anywhere. Without even knowing the underlying blockchain narrative, your users can manufacture new tokens.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="service-wrap d-flex align-center justify-center flex-column">
                  <div className="service-icon">
                    <img src="./images/smart-contracts.png" />
                  </div>
                  <h4 className="grident-color-sub-title text-center">Development of NFT Smart Contract
                  </h4>
                  <p className="sub-content">With our NFT smart contract developer, you may manage the digital asset while being assured of the information's immutability and transparency. Join forces with us to advance and experience prosperous growth.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="service-wrap  d-flex align-center justify-center flex-column">
                  <div className="service-icon">
                    <img src="./images/business.png" />
                  </div>
                  <h4 className="grident-color-sub-title text-center">Enable Trade
                  </h4>
                  <p className="sub-content">Create your own open trading platform based on NFT so that customers can buy, sell, and trade cryptocurrency assets. You can obtain a good start, development, and seamless procurement for the task with the help of the MetaBlock association. In the development open trade place, we are consistently the best.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="service-wrap d-flex align-center justify-center flex-column">
                  <div className="service-icon">
                    <img src="./images/collection.png" />
                  </div>
                  <h4 className="grident-color-sub-title text-center">Growth of Crypto Collectibles
                  </h4>
                  <p className="sub-content">We verify ownership of the digital items by analyzing your needs. You are able to claim ownership of digital assets and link them to your name. The asset's ownership is unchangeable using our NFT technology.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="service-wrap d-flex align-center justify-center flex-column">
                  <div className="service-icon">
                    <img src="./images/exchange.png" />
                  </div>
                  <h4 className="grident-color-sub-title text-center">NFT Exchange Progress
                  </h4>
                  <p className="sub-content">We provide safe NFT exchange development services with any Blockchain so that NFT tokens can be bought, sold, and traded. By minting the creation and selling them, let our developers investigate the several revenue streams.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="service-wrap d-flex align-center justify-center flex-column">
                  <div className="service-icon">
                    <img src="./images/nft.png" />
                  </div>
                  <h4 className="grident-color-sub-title text-center">Development of NFT Metaverse Platform
                  </h4>
                  <p className="sub-content">You can have a whole Metaverse NFT platform developed by our skilled NFT developers. You have the ability to expand your business globally and establish a unique online identity.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="service-wrap d-flex align-center justify-center flex-column">
                  <div className="service-icon">
                    <img src="./images/digital.png" />
                  </div>
                  <h4 className="grident-color-sub-title text-center">Development of NFT Storage Platforms
                  </h4>
                  <p className="sub-content">We provide a high-tech solution to enable the upload and manage NFTs in the IPFS platform so that you can have a safe storage platform. The safest way to keep NFT data, such as music, artwork, etc., is suggested by our storage system.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="service-wrap d-flex align-center justify-center flex-column">
                  <div className="service-icon">
                    <img src="./images/peer-to-peer.png" />
                  </div>
                  <h4 className="grident-color-sub-title text-center">The platform of NFT Lending
                  </h4>
                  <p className="sub-content">With our knowledge, we develop the most straightforward NFT Lending platform so you may start your own, easily adaptable platform. By investing NFT in liquid assets, it makes it possible for NFT owners to get simple returns.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="identity_anagement_with_nft p-20-0 bg_grey">
          <div className="container">
            <div className="row">
              <div className="col-md-12  text-center">
                <h3 className="defi_sec_titles text-center">NFT-Based Identity Management
                </h3>
                <p className="defi_sec_para">Utilizing state-of-the-art & seamless development skills, our developer will tokenize the tokens using an excellent solution for distributed micromanagement.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="how_defi_transform_world_box">
                  <div className="box_title">
                    <h4 className="ourservice-title ">NFT Upkeep and Assistance
                    </h4>
                  </div>
                  <div className="how_defi_transform_world_box_list">
                    <div className="row">
                      <div className="col-md-12">
                        <ul className="how_defi_transform_world_box_ul">
                          <div className="box_list_cntnt">For our clients' projects, we offer a comprehensive range of services for ongoing support and upkeep. With the newest versions, we handle project procurement and hassle-free upgrades. For your initial digital endeavor, we excel in seamless migration as well.
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="how_defi_transform_world_box">
                  <div className="box_title">
                    <h4 className="ourservice-title">Services for IPO Development.</h4>
                  </div>
                  <div className="how_defi_transform_world_box_list">
                    <div className="row">
                      <div className="col-md-12">
                        <ul className="how_defi_transform_world_box_ul">
                          <div className="box_list_cntnt">Use our fundraising NFT platform to launch your NFT company. You can create an NFT poster that links to Blockchain or Etherscan using a QR code. To guarantee your ground-breaking market presence and outstanding deals, we provide you with the most cutting-edge IPO development service. Pick us since we have the most expertise in IPO development</div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="features_of_our_nft_token_development">
          <div className="container-fluid">
            <div className="row row_justify">
              <div className="col-md-10">
                <div>
                  <h2 className="ourservice-titles-new grident-color-sub-title">Aspects of our development of NFT tokens</h2>
                  <div className="mgf_line">
                    <span className="line" />
                    <span className="line" />
                    <span className="line" />
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="mgd_advantage_card">
                          <p className="mgd_advantage_card_content">Equitable programming</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mgd_advantage_card">
                          <p className="mgd_advantage_card_content">Dividend Mapping</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mgd_advantage_card">
                          <p className="mgd_advantage_card_content"> Rise in liquid assets</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mgd_advantage_card">
                          <p className="mgd_advantage_card_content">SEC Automation</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mgd_advantage_card">
                          <p className="mgd_advantage_card_content">Safe-concept wallet</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mgd_advantage_card">
                          <p className="mgd_advantage_card_content">Worldwide Investing in Capital</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mgd_advantage_card">
                          <p className="mgd_advantage_card_content">Enhanced market efficacy</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mgd_advantage_card">
                          <p className="mgd_advantage_card_content">Absence of middlemen</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="benefits_of_the_nft_token_development">
          <div className="container-fluid">
            <div className="row row_justify">
              <div className="col-md-11">
                <div>
                  <h3 className="ourservice-titles-new grident-color-sub-title">Advantages Of The NFT Token Creation</h3>
                  <div className="mgf_line">
                  </div>
                  <div>
                    <div className="row row_align">
                      <div className="col-md-6">
                        <div>
                          <ul className="mgd_why_metablock_ul">
                            <li>
                              <i className="fa fa-gamepad" aria-hidden="true" />
                              <p>Chain-specific NFTMarketplace</p>
                            </li>
                            <li>
                              <i className="fa fa-gamepad" aria-hidden="true" />
                              <p>NFT Investment</p>
                            </li>
                            <li>
                              <i className="fa fa-gamepad" aria-hidden="true" />
                              <p>Launchpad for NFT</p>
                            </li>
                            <li>
                              <i className="fa fa-gamepad" aria-hidden="true" />
                              <p>NFT Securities</p>
                            </li>
                            <li>
                              <i className="fa fa-gamepad" aria-hidden="true" />
                              <p>Partially Holding</p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div>
                          <ul>
                            <li>
                              <i className="fa fa-gamepad" aria-hidden="true" />
                              <p>Intelligent Contract</p>
                            </li>
                            <li>
                              <i className="fa fa-gamepad" aria-hidden="true" />
                              <p>Reviewed NFT audit</p>
                            </li>
                            <li>
                              <i className="fa fa-gamepad" aria-hidden="true" />
                              <p>Financing NFT</p>
                            </li>
                            <li>
                              <i className="fa fa-gamepad" aria-hidden="true" />
                              <p>Auctioning off and bidding</p>
                            </li>
                            <li>
                              <i className="fa fa-gamepad" aria-hidden="true" />
                              <p>Lazy Minting & IPFS NFT Storage</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="we_help_you_with_qualified_services like pb-5 pt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 text-center mb-4">
                <div className="service-title">
                  <h4 className="ourservice-titles-new grident-color-sub-title">Benefits of Non-Fungible Token Development</h4>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6 col-md-6 text-center mb-3">
                <div className="pricing-table">
                  <div className="pricing-header ">
                    <h3>basic</h3>
                  </div>
                  <div className="pricingContent">
                    <ul>
                      <li>They are able to authenticate their ownership.</li>
                      <li>Data is unchangeable, hence it cannot be altered.</li>
                      <li>The holder has the finest chance of selling and receiving royalties from the original inventor.</li>
                      <li>By retaining the token for an extended amount of time, the holder can profitably and stress-free.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 text-center mb-3">
                <div className="pricing-table blue">
                  <div className="pricing-header">
                    <h3>normative</h3>
                  </div>
                  <div className="pricingContent">
                    <ul>
                      <li>They are able to demonstrate their ownership.</li>
                      <li>Supply and scarcity can be managed by the maker.</li>
                      <li>You will receive lifetime royalties when each token is sold, just like the holder.</li>
                      <li>Being able to trade on any peer-to-peer network.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="nft_token_development_standards pb-5 pt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 text-center mb-4">
                <div className="service-title">
                  <h4 className="ourservice-titles-new grident-color-sub-title">Development Guidelines for NFT Tokens</h4>
                </div>
              </div>
            </div>
            <div className="wrapper_new">
              <div className="row">
                <div className="col-lg-6 col-md-6 text-center mb-3">
                  <div className="speechbubble odd_item color_1">
                    <h3 className="sub_title tit_clr1">ERC-721</h3>
                    <p className="contant">
                      Ownership of non-fungible tokens is described using this standard. With several potential extensions and distributed across multiple contracts, it is a more complex standard than ERC20.</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 text-center mb-3">
                  <div className="speechbubble even_item color_2">
                    <h3 className="sub_title tit_clr2">ERC-998</h3>
                    <p className="contant">
                      This token standard is an enhanced iteration of the ERC-721 protocol. As a result, consumers are able to trade numerous NFTs in one transaction.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 text-center mb-3">
                  <div className="speechbubble odd_item color_3">
                    <h3 className="sub_title tit_clr3">ERC-1155</h3>
                    <p className="contant">
                      It is exchangeable for both non-fungible and fungible tokens. The user's transaction charge during trading will be decreased by this ERC-1155 token standard.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 text-center mb-3">
                  <div className="speechbubble even_item color_4">
                    <h3 className="sub_title tit_clr4">TRC-721</h3>
                    <p className="contant">
                      It is equivalent to the Ethereum token ERC -721 standard, with the exception that ERC -721 is used on the TRON blockchain.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 text-center mb-3">
                  <div className="speechbubble odd_item color_5">
                    <h3 className="sub_title tit_clr5">BEP721</h3>
                    <p className="contant">
                      Non-fungible tokens (NFTs) are created using TRC721, ERC721, and this BSC NFT standard.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 text-center mb-3">
                  <div className="speechbubble even_item color_6">
                    <h3 className="sub_title tit_clr6">BEP1155</h3>
                    <p className="contant">
                      The BSC network's multi-token standard, which incorporates BEP20's fungibility and BEP721's non-fungibility into a single contract.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="use_cases_of_non_fungible_token pb-5 pt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 text-center mb-4">
                <div className="service-title">
                  <h4 className="ourservice-title grident-color-sub-title">Applications for Non-Fungible Tokens</h4>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="box">
                  <div className="top-bar" />
                  <div className="top">
                    <input type="checkbox" className="heart-btn" id="heart-btn-1" />
                  </div>
                  <div className="content" >
                    <img src="./images/hobby.png" alt="Arts & Music" title="Arts & Music" />
                    <strong>Music and the Arts</strong>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="box">
                  <div className="top-bar" />
                  <div className="top">
                    <input type="checkbox" className="heart-btn" id="heart-btn-1" />
                  </div>
                  <div className="content">
                    <img src="./images/sports.png" alt="Games" title="Games" />
                    <strong>Sports</strong>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="box">
                  <div className="top-bar" />
                  <div className="top">
                    <input type="checkbox" className="heart-btn" id="heart-btn-1" />
                  </div>
                  <div className="content">
                    <img src="./images/digital.png" alt="Digital Content" title="Digital Content" />
                    <strong>Digital Media</strong>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="box">
                  <div className="top-bar" />
                  <div className="top">
                    <input type="checkbox" className="heart-btn" id="heart-btn-1" />
                  </div>
                  <div className="content">
                    <img src="./images/web-domain.png" alt="Domain Names" title="Domain Names" />
                    <strong>Domain Terms</strong>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="box">
                  <div className="top-bar" />
                  <div className="top">
                    <input type="checkbox" className="heart-btn" id="heart-btn-1" />
                  </div>
                  <div className="content">
                    <img src="./images/trend.png" alt="Fashions" title="Fashions" />
                    <strong>Trends</strong>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="box">
                  <div className="top-bar" />
                  <div className="top">
                    <input type="checkbox" className="heart-btn" id="heart-btn-1" />
                  </div>
                  <div className="content">
                    <img src="./images/assortment.png" alt="Collectibles" title="Collectibles" />
                    <strong>Assortments</strong>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="box">
                  <div className="top-bar" />
                  <div className="top">
                    <input type="checkbox" className="heart-btn" id="heart-btn-1" />
                  </div>
                  <div className="content">
                    <img src="./images/assets.png" alt="Real Estate" title="Real Estate" />
                    <strong>Actual Property</strong>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="box">
                  <div className="top-bar" />
                  <div className="top">
                    <input type="checkbox" className="heart-btn" id="heart-btn-1" />
                  </div>
                  <div className="content">
                    <img src="./images/baseball.png" alt="Fantasy Sports" title="Fantasy Sports" />
                    <strong>Engage in Fantasy Sports</strong>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-center mb-3">
                <div className="box">
                  <div className="top-bar" />
                  <div className="top">
                    <input type="checkbox" className="heart-btn" id="heart-btn-1" />
                  </div>
                  <div className="content">
                    <img src="./images/member-card.png" alt="Subscription" title="Subscription" />
                    <strong>Membership</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="trending_nft_tokens_list">
          <div className="container-fluid h-100">
            <div className="nftgp_custom_container">
              <div className="row  ">
                <div className="col-md-12 ">
                  <div className="nftgp_sectitle_wrap">
                    <h3 className="nftgp_sec_title title_alict text-center">Top 10 Most Popular NFT Tokens List</h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 ">
                  <ul>
                    <li className>
                      <div className="nftgp_box  ">
                        <div className="nftgp_img">
                        </div>
                        <div className="nftgp_desc">The Sandbox </div>
                        <p>(SAND, $595 million)</p>
                      </div>
                    </li>
                    <li>
                      <div className="nftgp_box">
                        <div className="nftgp_img">
                        </div>
                        <div className="nftgp_desc">WAX </div>
                        <p>(WAXP, $638 million)</p>
                      </div>
                    </li>
                    <li>
                      <div className="nftgp_box">
                        <div className="nftgp_img">
                        </div>
                        <div className="nftgp_desc">DigiByte </div>
                        <p>(DGB, $927 million)</p>
                      </div>
                    </li>
                    <li>
                      <div className="nftgp_box">
                        <div className="nftgp_img">
                        </div>
                        <div className="nftgp_desc">Flow </div>
                        <p>(FLOW, $1,212 million)</p>
                      </div>
                    </li>
                    <li>
                      <div className="nftgp_box">
                        <div className="nftgp_img">
                        </div>
                        <div className="nftgp_desc">Tezos</div>
                        <p> (XTZ, $3,912 million)</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 ">
                  <ul>
                    <li className>
                      <div className="nftgp_box  ">
                        <div className="nftgp_img">
                        </div>
                        <div className="nftgp_desc">Decentraland </div>
                        <p>(MANA, $1,574 million)</p>
                      </div>
                    </li>
                    <li>
                      <div className="nftgp_box">
                        <div className="nftgp_img">
                        </div>
                        <div className="nftgp_desc">Enjin Coin</div>
                        <p> (ENJ, $1,578 million)</p>
                      </div>
                    </li>
                    <li>
                      <div className="nftgp_box">
                        <div className="nftgp_img">
                        </div>
                        <div className="nftgp_desc">Chiliz </div>
                        <p>(CHZ, $2,110 million)</p>
                      </div>
                    </li>
                    <li>
                      <div className="nftgp_box">
                        <div className="nftgp_img">
                        </div>
                        <div className="nftgp_desc">Axie Infinity </div>
                        <p>(AXS, $4,291 million)</p>
                      </div>
                    </li>
                    <li>
                      <div className="nftgp_box">
                        <div className="nftgp_img">
                        </div>
                        <div className="nftgp_desc">THETA</div>
                        <p> (THETA, $6,951 million)</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="launch_your_nft_token">
          <div className="container-fluid">
            <div className="row row_just">
              <div className="col-lg-10">
                <div className="blockchain_bg">
                  <h2 className="ourservice-titles-new">Start using your NFT token from the NFT development company.</h2>
                  <p className="contents">As a top NFT development company, MetaBlock offers rich feature and functional NFT token development services over a secure blockchain through NFT mint. Depending on your company needs, we help you create your NFT Token for a variety of goods, including digital collectibles, music, films, images, and artwork, over different blockchains like Tron, Ethereum, and Binance SmartChain.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="we_help_you_with_qualified_services like pb-5 pt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 text-center mb-4">
                <div className="service-title">
                  <h4 className="ourservice-titles-new grident-color-sub-title">With our expert services, we assist you with</h4>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-3 col-md-6 text-center mb-3">
                <div className="single-person">
                  <div className="person-image">
                    {/* <img src=" " className="icon_sprit" style={{ objectPosition: '0px 0px' }} /> */}
                    <span className="icon">
                      <img src="./images/strong-motivated.png" />
                    </span>
                  </div>
                  <div className="person-info">
                    <span className="speciality">Strong Platform</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center mb-3">
                <div className="single-person">
                  <div className="person-image">

                    <span className="icon">
                      <img src="./images/extremely-secure.png" />
                    </span>
                  </div>
                  <div className="person-info">
                    <span className="speciality">Extremely Secure</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center mb-3">
                <div className="single-person">
                  <div className="person-image">

                    <span className="icon">
                      <img src="./images/Personalization-audience.png" />
                    </span>
                  </div>
                  <div className="person-info">
                    <span className="speciality">Personalization</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center mb-3">
                <div className="single-person">
                  <div className="person-image">

                    <span className="icon">
                      <img src="./images/API-Conversion.png" />
                    </span>
                  </div>
                  <div className="person-info">
                    <span className="speciality">API Conversion</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-3 col-md-6 text-center mb-3">
                <div className="single-person">
                  <div className="person-image">

                    <span className="icon">
                      <img src="./images/Delivery-on-schedule.png" />
                    </span>
                  </div>
                  <div className="person-info">
                    <span className="speciality">Delivery on schedule</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center mb-3">
                <div className="single-person">
                  <div className="person-image">

                    <span className="icon">
                      <img src="./images/Manifesto.png" />
                    </span>
                  </div>
                  <div className="person-info">
                    <span className="speciality">Manifesto</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center mb-3">
                <div className="single-person">
                  <div className="person-image">

                    <span className="icon">
                      <img src="./images/Dispersed.png" />
                    </span>
                  </div>
                  <div className="person-info">
                    <span className="speciality">Dispersed</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center mb-3">
                <div className="single-person">
                  <div className="person-image">
                    <span className="icon">
                      <img src="./images/robotics.png" />
                    </span>
                  </div>
                  <div className="person-info">
                    <span className="speciality">Continuous Support </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>




    </div>
  )
}

export default NFTToken
