import React from 'react'
import { FaWhatsapp } from 'react-icons/fa';
import { Link } from "react-router-dom";

const TrandingProduct = () => {
    return (
        <section id="features" className="position-relative  fade-in one">

            <div className="container px-5 ">
                <div className="row gx-5 align-items-center ">
                    <div className="col-lg-12 order-lg-1 mb-5 mb-lg-0 ">
                        <div className=" w-100 d-flex align-items-center justify-content-center flex-column">
                            <h1 className="display-6 pb-3 pb-2 text-center pt-5 glow-text lh-1 mb-3 text-main fw-bold section-title g-text">
                                Trending Products we offer</h1>
                            <p className="text-center">If you are thinking to start an Online Ludo, Poker Room, Rummy or casino need to improve your existing gaming platform,<br />
                                So  you have come the right place! We Provide Best online gaming and mobile app solutions.
                            </p>
                        </div>

                        <div className="container-fluid justify-content-center py-5 raw d-flex flex-wrap">
                            <div className="col-md-3 mb-5 ">

                                <div className=" d-flex flex-column align-items-center ">
                                    <div className='know-more-div'>
                                        <div className="img-box-10 mb-3 overflow-hidden shadow ">
                                            <img className='tradings-image' src="./images/image24.jpg" alt />
                                        </div>
                                        <Link to="/blockchaindevelopment">
                                            <div className="know-more-button">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>
                                    {/* <i class="bi-phone icon-feature text-gradient d-block mb-3 "></i> */}
                                    <h3 className="fs-5 pb-4 pt-2 text-white text-center">Bockchain Development
                                    </h3>
                                    <div className='trending-product-buttons-div'>
                                        <div className="start-chat-button-mobile">

                                            <a href="https://wa.me/9358593003" className="color-btn nohover gap-2">Start
                                                chat <FaWhatsapp name="logo-whatsapp" className="fs-5" /></a>
                                            {/* <a href="https://wa.me/message/L3YD6DCLY2GQL1"><img class="app-badge" width="200px" src="assets/img/button-demo.png" alt="..." /></a> */}
                                        </div>
                                        <Link to="/blockchaindevelopment">
                                            <div className="know-more-button-mobile">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-5 ">
                                {/* Feature item*/}
                                <div className="-1 d-flex flex-column align-items-center ">
                                    <div className='know-more-div'>
                                        <div className="img-box-10 mb-3 overflow-hidden shadow ">
                                            <img className='tradings-image' style={{ width: '100%', height: '100%' }} src="./images/image25.jpg" alt />
                                        </div>
                                        <Link to="/SemiFungibleTokenDevelopment">
                                            <div className="know-more-button">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>
                                    {/* <i class="bi-phone icon-feature text-gradient d-block mb-3 "></i> */}
                                    <h3 className="fs-5 pb-4 pt-2 text-white text-center">Token Development</h3>
                                    <div className='trending-product-buttons-div'>
                                        <div className="start-chat-button-mobile">

                                            <a href="https://wa.me/9358593003" className="color-btn nohover gap-2">Start
                                                chat <FaWhatsapp name="logo-whatsapp" className="fs-5" /></a>
                                            {/* <a href="https://wa.me/message/L3YD6DCLY2GQL1"><img class="app-badge" width="200px" src="assets/img/button-demo.png" alt="..." /></a> */}
                                        </div>
                                        <Link to="/SemiFungibleTokenDevelopment">
                                            <div className="know-more-button-mobile">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-5 ">
                                {/* Feature item*/}
                                <div className=" d-flex flex-column align-items-center ">
                                    <div className='know-more-div'>
                                        <div className="img-box-10 mb-3 overflow-hidden shadow ">
                                            <img className='tradings-image' src="./images/image26.jpg" alt />
                                        </div>
                                        <Link to="/PancakeswapExchange">
                                            <div className="know-more-button">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>

                                    {/* <i class="bi-phone icon-feature text-gradient d-block mb-3 "></i> */}
                                    <h3 className="fs-5 pb-4 pt-2 text-white text-center">DEX  Development
                                    </h3>
                                    <div className='trending-product-buttons-div'>
                                        <div className="start-chat-button-mobile">

                                            <a href="https://wa.me/9358593003" className="color-btn nohover gap-2">Start
                                                chat <FaWhatsapp name="logo-whatsapp" className="fs-5" /></a>
                                            {/* <a href="https://wa.me/message/L3YD6DCLY2GQL1"><img class="app-badge" width="200px" src="assets/img/button-demo.png" alt="..." /></a> */}
                                        </div>
                                        <Link to="/PancakeswapExchange">
                                            <div className="know-more-button-mobile">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-5 ">
                                {/* Feature item*/}
                                <div className=" d-flex flex-column align-items-center ">
                                    <div className='know-more-div'>
                                        <div className="img-box-10 mb-3 overflow-hidden shadow ">
                                            <img className='tradings-image' src="./images/image27.jpg" alt />
                                        </div>
                                        <Link to="/LunchpadDevelopment">
                                            <div className="know-more-button">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>

                                    {/* <i class="bi-phone icon-feature text-gradient d-block mb-3 "></i> */}
                                    <h3 className="fs-5 pb-4 pt-2 text-white text-center ">Lunchpad Development</h3>
                                    <div className='trending-product-buttons-div'>
                                        <div className="start-chat-button-mobile">

                                            <a href="https://wa.me/9358593003" className="color-btn nohover gap-2">Start
                                                chat <FaWhatsapp name="logo-whatsapp" className="fs-5" /></a>
                                            {/* <a href="https://wa.me/message/L3YD6DCLY2GQL1"><img class="app-badge" width="200px" src="assets/img/button-demo.png" alt="..." /></a> */}
                                        </div>
                                        <Link to="/LunchpadDevelopment">
                                            <div className="know-more-button-mobile">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid justify-content-center  raw d-flex flex-wrap">
                            <div className="col-md-3 mb-5 ">
                                {/* Feature item*/}
                                <div className=" d-flex flex-column align-items-center ">
                                    <div className='know-more-div'>
                                        <div className="img-box-10 mb-3 overflow-hidden shadow ">
                                            <img src="./images/image32.jpg" alt className='tradings-image' />
                                        </div>
                                        <Link to="/AccountBillingSoftware">
                                            <div className="know-more-button">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>

                                    {/* <i class="bi-phone icon-feature text-gradient d-block mb-3 "></i> */}
                                    <h3 className="fs-5 pb-4 pt-2 text-white text-center">Accounting & Billing Software</h3>
                                    <div className='trending-product-buttons-div'>
                                        <div className="start-chat-button-mobile">

                                            <a href="https://wa.me/9358593003" className="color-btn nohover gap-2">Start
                                                chat <FaWhatsapp name="logo-whatsapp" className="fs-5" /></a>
                                            {/* <a href="https://wa.me/message/L3YD6DCLY2GQL1"><img class="app-badge" width="200px" src="assets/img/button-demo.png" alt="..." /></a> */}
                                        </div>
                                        <Link to="/AccountBillingSoftware">
                                            <div className="know-more-button-mobile">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-5 ">
                                {/* Feature item*/}
                                <div className=" d-flex flex-column align-items-center ">

                                    <div className='know-more-div'>
                                        <div className="img-box-10 mb-3 overflow-hidden shadow ">
                                            <img className='tradings-image' src="./images/image33.jpg" alt />
                                        </div>
                                        <Link to="/RestaurantManagmentSoftware">
                                            <div className="know-more-button">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>

                                    {/* <i class="bi-phone icon-feature text-gradient d-block mb-3 "></i> */}
                                    <h3 className="fs-5 pb-4 pt-2 text-white text-center">Restaurant Managment System</h3>
                                    <div className='trending-product-buttons-div'>
                                        <div className="start-chat-button-mobile">

                                            <a href="https://wa.me/9358593003" className="color-btn nohover gap-2">Start
                                                chat <FaWhatsapp name="logo-whatsapp" className="fs-5" /></a>
                                            {/* <a href="https://wa.me/message/L3YD6DCLY2GQL1"><img class="app-badge" width="200px" src="assets/img/button-demo.png" alt="..." /></a> */}
                                        </div>
                                        <Link to="/RestaurantManagmentSoftware">
                                            <div className="know-more-button-mobile">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-5 ">
                                {/* Feature item*/}
                                <div className=" d-flex flex-column align-items-center ">
                                    <div className='know-more-div'>
                                        <div className="img-box-10 mb-3 overflow-hidden shadow ">
                                            <img className='tradings-image' src="./images/image34.jpg" alt />
                                        </div>
                                        <Link to="/ClinicManagmentSoftware">
                                            <div className="know-more-button">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>

                                    {/* <i class="bi-phone icon-feature text-gradient d-block mb-3 "></i> */}
                                    <h3 className="fs-5 pb-4 pt-2 text-white text-center">Clinic Managment </h3>
                                    <div className='trending-product-buttons-div'>
                                        <div className="start-chat-button-mobile">

                                            <a href="https://wa.me/9358593003" className="color-btn nohover gap-2">Start
                                                chat <FaWhatsapp name="logo-whatsapp" className="fs-5" /></a>
                                            {/* <a href="https://wa.me/message/L3YD6DCLY2GQL1"><img class="app-badge" width="200px" src="assets/img/button-demo.png" alt="..." /></a> */}
                                        </div>
                                        <Link to="/ClinicManagmentSoftware">
                                            <div className="know-more-button-mobile">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-5 ">
                                {/* Feature item*/}
                                <div className=" d-flex flex-column align-items-center ">
                                    <div className='know-more-div'>
                                        <div className="img-box-10 mb-3 overflow-hidden shadow ">
                                            <img className='tradings-image' src="./images/image35.jpg" alt />
                                        </div>
                                        <Link to="/RealstateSoftware">
                                            <div className="know-more-button">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>

                                    {/* <i class="bi-phone icon-feature text-gradient d-block mb-3 "></i> */}
                                    <h3 className="fs-5 pb-4 pt-2 text-white text-center ">Real state Managment
                                    </h3>
                                    <div className='trending-product-buttons-div'>
                                        <div className="start-chat-button-mobile">

                                            <a href="https://wa.me/9358593003" className="color-btn nohover gap-2">Start
                                                chat <FaWhatsapp name="logo-whatsapp" className="fs-5" /></a>
                                            {/* <a href="https://wa.me/message/L3YD6DCLY2GQL1"><img class="app-badge" width="200px" src="assets/img/button-demo.png" alt="..." /></a> */}
                                        </div>
                                        <Link to="/RealstateSoftware">
                                            <div className="know-more-button-mobile">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid justify-content-center  raw d-flex flex-wrap">
                            <div className="col-md-3 mb-5 ">
                                {/* Feature item*/}
                                <div className=" d-flex flex-column align-items-center ">
                                    <div className='know-more-div'>
                                        <div className="img-box-10 mb-3 overflow-hidden shadow ">
                                            <img className='tradings-image' src="./images/image28.jpg" alt />
                                        </div>
                                        <Link to="/FantasyCricketGameDevelopment">
                                            <div className="know-more-button">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>

                                    {/* <i class="bi-phone icon-feature text-gradient d-block mb-3 "></i> */}
                                    <h3 className="fs-5 pb-4 pt-2 text-white text-center">Fantasy Cricket Game  </h3>
                                    <div className='trending-product-buttons-div'>
                                        <div className="start-chat-button-mobile">

                                            <a href="https://wa.me/9358593003" className="color-btn nohover gap-2">Start
                                                chat <FaWhatsapp name="logo-whatsapp" className="fs-5" /></a>
                                            {/* <a href="https://wa.me/message/L3YD6DCLY2GQL1"><img class="app-badge" width="200px" src="assets/img/button-demo.png" alt="..." /></a> */}
                                        </div>
                                        <Link to="/FantasyCricketGameDevelopment">
                                            <div className="know-more-button-mobile">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-5 ">
                                {/* Feature item*/}
                                <div className=" d-flex flex-column align-items-center ">

                                    <div className='know-more-div'>
                                        <div className="img-box-10 mb-3 overflow-hidden shadow ">
                                            <img className='tradings-image' src="./images/image29.jpg" alt />
                                        </div>
                                        <Link to="/GameDevelopment">
                                            <div className="know-more-button">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>

                                    {/* <i class="bi-phone icon-feature text-gradient d-block mb-3 "></i> */}
                                    <h3 className="fs-5 pb-4 pt-2 text-white text-center">Game Development</h3>
                                    <div className='trending-product-buttons-div'>
                                        <div className="start-chat-button-mobile">

                                            <a href="https://wa.me/9358593003" className="color-btn nohover gap-2">Start
                                                chat <FaWhatsapp name="logo-whatsapp" className="fs-5" /></a>
                                            {/* <a href="https://wa.me/message/L3YD6DCLY2GQL1"><img class="app-badge" width="200px" src="assets/img/button-demo.png" alt="..." /></a> */}
                                        </div>
                                        <Link to="/GameDevelopment">
                                            <div className="know-more-button-mobile">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-5 ">
                                {/* Feature item*/}
                                <div className=" d-flex flex-column align-items-center ">

                                    <div className='know-more-div'>
                                        <div className="img-box-10 mb-3 overflow-hidden shadow ">
                                            <img className='tradings-image' src="./images/image30.jpg" alt />
                                        </div>
                                        <Link to="/MetaverseGame">
                                            <div className="know-more-button">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>

                                    {/* <i class="bi-phone icon-feature text-gradient d-block mb-3 "></i> */}
                                    <h3 className="fs-5 pb-4 pt-2 text-white text-center">Metaverse Game  </h3>
                                    <div className='trending-product-buttons-div'>
                                        <div className="start-chat-button-mobile">

                                            <a href="https://wa.me/9358593003" className="color-btn nohover gap-2">Start
                                                chat <FaWhatsapp name="logo-whatsapp" className="fs-5" /></a>
                                            {/* <a href="https://wa.me/message/L3YD6DCLY2GQL1"><img class="app-badge" width="200px" src="assets/img/button-demo.png" alt="..." /></a> */}
                                        </div>
                                        <Link to="/MetaverseGame">
                                            <div className="know-more-button-mobile">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 mb-5 ">
                                {/* Feature item*/}
                                <div className=" d-flex flex-column align-items-center ">
                                    <div className='know-more-div'>
                                        <div className="img-box-10 mb-3 overflow-hidden shadow ">
                                            <img className='tradings-image' src="./images/casinogame.jpg" alt />
                                        </div>
                                        <Link to="/PokerGameDevelopment">
                                            <div className="know-more-button">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>

                                    {/* <i class="bi-phone icon-feature text-gradient d-block mb-3 "></i> */}
                                    <h3 className="fs-5 pb-4 pt-2 text-white text-center center ">Casino Game </h3>
                                    <div className='trending-product-buttons-div'>
                                        <div className="start-chat-button-mobile">

                                            <a href="https://wa.me/9358593003" className="color-btn nohover gap-2">Start
                                                chat <FaWhatsapp name="logo-whatsapp" className="fs-5" /></a>
                                            {/* <a href="https://wa.me/message/L3YD6DCLY2GQL1"><img class="app-badge" width="200px" src="assets/img/button-demo.png" alt="..." /></a> */}
                                        </div>
                                        <Link to="/PokerGameDevelopment">
                                            <div className="know-more-button-mobile">
                                                <a className="color-btn  gap-2">
                                                    Know More </a>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TrandingProduct
