import React from 'react'

const DatingKeyFeature = () => {
    return (
        <div style={{ marginBottom: "50px " }}>
            <div className="dashboard-main-box">
                <h4 className="Key-feature-heading mb-5" style={{ fontSize: '40px' }}>Key Feature</h4>
                <div className="dashboard-container">
                    <div className="dashboard-container-box dashboard-boxes">
                        <div className="key-feature-heading">
                            <img
                                src="./images/user.png"
                                alt=""
                            />

                            <h4 className="software-feature-headings" >Profile Creation</h4>
                            <p>
                                Users meticulously craft their profiles, adding photos and personal details to showcase their personalities and interests.
                            </p>
                        </div>
                    </div>

                    <div className="dashboard-container-box dashboard-boxes">
                        <div className="key-feature-heading">
                            <img
                                src="./images/match.png"
                                alt=""
                            />

                            <h4 className="software-feature-headings" >Smart Matching</h4>
                            <p>
                                Advanced algorithms analyze user data to suggest compatible matches, ensuring higher chances of meaningful connections.
                            </p>
                        </div>
                    </div>
                    <div className="dashboard-container-box dashboard-boxes">
                        <div className="key-feature-heading">
                            <img
                                src="./images/advanced-search.png"
                                alt=""
                            />

                            <h4 className="software-feature-headings" >Advanced Search</h4>
                            <p>
                                With comprehensive filters, users can fine-tune their search criteria, enabling them to find potential matches tailored to their preferences.
                            </p>
                        </div>
                    </div>

                    <div className="dashboard-container-box dashboard-boxes">
                        <div className="key-feature-heading">
                            <img
                                src="./images/speech-bubble.png"
                                alt=""
                            />

                            <h4 className="software-feature-headings" >Instant Messaging</h4>
                            <p>
                                Seamless chat features facilitate effortless communication between matches, allowing users to connect in real-time and build rapport.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="dashboard-container">
                    <div className="dashboard-container-box dashboard-boxes">
                        <div className="key-feature-heading">
                            <img
                                src="./image/immigration.png"
                                alt=""
                            />

                            <h4 className="software-feature-headings" >Photo Verification</h4>
                            <p>
                                Profile authenticity is reinforced through photo verification tools, offering users confidence and trust in the platform's integrity.
                            </p>
                        </div>
                    </div>

                    <div className="dashboard-container-box dashboard-boxes">
                        <div className="key-feature-heading">
                            <img
                                src="./images/map.png"
                                alt=""
                            />
                            <h4 className="software-feature-headings" >Location-Based</h4>
                            <p>
                                Utilizing geolocation technology, the app matches users based on their proximity, enhancing the likelihood of local connections.
                            </p>
                        </div>
                    </div>
                    <div className="dashboard-container-box dashboard-boxes">
                        <div className="key-feature-heading">
                            <img
                                src="./images/swipe.png"
                                alt=""
                            />

                            <h4 className="software-feature-headings" >Swipe Interaction</h4>
                            <p>
                                Users engage with profiles through intuitive swiping gestures, effortlessly expressing interest or passing on potential matches.
                            </p>
                        </div>
                    </div>

                    <div className="dashboard-container-box dashboard-boxes">
                        <div className="key-feature-heading">
                            <img
                                src="./images/access.png"
                                alt=""
                            />

                            <h4 className="software-feature-headings" >Privacy Controls</h4>
                            <p>
                                Empowering users, privacy controls allow them to manage their profile visibility and communication preferences with ease.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DatingKeyFeature
