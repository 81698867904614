import './App.css';
import About from "./Component/About";
import Footer from "./Component/Footer";
import AllRouteFiles from "./AllRouteFiles";
import { BrowserRouter, Link } from "react-router-dom";
import { FaPhoneSquareAlt, FaWhatsapp } from "react-icons/fa";
import Chat from './Chat';
import ErrorPage from './ErrorPage';
import Sitemap from './Sitemap';
function App() {

  const encodedMessage = encodeURIComponent("Hello, I have an inquiry related to software services. I'm reaching out to gather more information about the software solutions your company provides.");

  // Define the Whatsapp link with the pre-filled message
  const whatsappLink = `https://wa.me/919358593003?text=${encodedMessage}`;
  return (
    <div className="App">


      <AllRouteFiles />
      <About />
      <Footer />


      <div className="enq-side-btn-icone ">
        <a href="tel:+919358593003"><img style={{ width: "28px" }} src="./images/telephone2.png" alt="Call" /></a>
        <a target='_blank' href={whatsappLink}>
          <img style={{ width: "28px" }} src="./images/whatsapp2.png" />
        </a>
        <a href='https://join.skype.com/invite/tDpNV7DJCcb1' target='_blank'>
          <img style={{ width: "28px" }} src="./images/skype-button.png" alt="" />
        </a>
      </div>
    </div>
  );
}

export default App;
